var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('a-card',{staticClass:"container"},[_c('a-table',{attrs:{"bordered":"","dataSource":_vm.list,"pagination":false}},[_c('a-table-column',{attrs:{"title":"序号","width":"50px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text, row, index){return [_vm._v(" "+_vm._s(index + 1)+" ")]}}])}),_c('a-table-column',{attrs:{"title":"中文名","data-index":"name"}}),_c('a-table-column',{attrs:{"title":"英文名","data-index":"code"}}),_c('a-table-column',{attrs:{"title":"设计值"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_vm._v(" "+_vm._s(text.designValue)+" ")]}}])}),_c('a-table-column',{attrs:{"title":"类型","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('DataDictFinder',{attrs:{"dictType":"bim_value_type","dictValue":String(text.valueClass)}})]}}])}),_c('a-table-column',{attrs:{"title":"数值精度","data-index":"decimalPrecision","align":"center"}}),_c('a-table-column',{attrs:{"title":"单位","data-index":"unit","align":"center"}}),_c('a-table-column',{attrs:{"title":"参考信息","data-index":"remark"}}),_c('a-table-column',{attrs:{"title":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('a-space',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.editText(text)}}},[_vm._v("编辑")])])]}}])})],1)],1),_c('a-modal',{attrs:{"visible":_vm.visible,"title":"修改参数","footer":null},on:{"cancel":_vm.cancel}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 16 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"中文名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            {
              initialValue: _vm.activeParam.name,
              rules: [{ required: true, message: '请输入！' }],
            },
          ]),expression:"[\n            'name',\n            {\n              initialValue: activeParam.name,\n              rules: [{ required: true, message: '请输入！' }],\n            },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"英文名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'code',
            {
              initialValue: _vm.activeParam.code,
              rules: [{ required: true, message: '请输入！' }],
            },
          ]),expression:"[\n            'code',\n            {\n              initialValue: activeParam.code,\n              rules: [{ required: true, message: '请输入！' }],\n            },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"类型"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'valueClass',
            {
              initialValue: _vm.activeParam.valueClass,
              rules: [{ required: true, message: '请选择！' }],
            },
          ]),expression:"[\n            'valueClass',\n            {\n              initialValue: activeParam.valueClass,\n              rules: [{ required: true, message: '请选择！' }],\n            },\n          ]"}],attrs:{"button-style":"solid"}},_vm._l((_vm.valueTypeList),function(item){return _c('a-radio',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1),_c('a-form-item',{attrs:{"label":"设计值"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'designValue',
            {
              initialValue: _vm.activeParam.designValue,
            },
          ]),expression:"[\n            'designValue',\n            {\n              initialValue: activeParam.designValue,\n            },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"数值精度"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'decimalPrecision',
            {
              initialValue: _vm.activeParam.decimalPrecision,
            },
          ]),expression:"[\n            'decimalPrecision',\n            {\n              initialValue: activeParam.decimalPrecision,\n            },\n          ]"}],staticStyle:{"width":"100%"},attrs:{"min":0,"max":16}})],1),_c('a-form-item',{attrs:{"label":"单位"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'unit',
            {
              initialValue: _vm.activeParam.unit,
              rules: [{ required: true, message: '请输入！' }],
            },
          ]),expression:"[\n            'unit',\n            {\n              initialValue: activeParam.unit,\n              rules: [{ required: true, message: '请输入！' }],\n            },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'remark',
            {
              initialValue: _vm.activeParam.remark,
            },
          ]),expression:"[\n            'remark',\n            {\n              initialValue: activeParam.remark,\n            },\n          ]"}],attrs:{"auto-size":{ minRows: 3 }}})],1),_c('div',{staticClass:"right"},[_c('a-space',[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("取消")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.saveLoading}},[_vm._v("保存")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }