<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-table bordered :dataSource="list" :pagination="false">
        <a-table-column title="序号" width="50px" align="center">
          <template slot-scope="text, row, index">
            {{ index + 1 }}
          </template>
        </a-table-column>

        <a-table-column title="中文名" data-index="name" />
        <a-table-column title="英文名" data-index="code" />
        <a-table-column title="设计值">
          <template slot-scope="text">
            {{ text.designValue }}
          </template>
        </a-table-column>
        <a-table-column title="类型" align="center">
          <template slot-scope="text">
            <DataDictFinder
              dictType="bim_value_type"
              :dictValue="String(text.valueClass)"
            />
          </template>
        </a-table-column>
        <a-table-column
          title="数值精度"
          data-index="decimalPrecision"
          align="center"
        />
        <a-table-column title="单位" data-index="unit" align="center" />
        <a-table-column title="参考信息" data-index="remark" />
        <a-table-column title="操作" align="center">
          <template slot-scope="text">
            <a-space>
              <a href="#" @click.prevent="editText(text)">编辑</a>
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>

    <a-modal
      :visible="visible"
      title="修改参数"
      @cancel="cancel"
      :footer="null"
    >
      <a-form
        :form="form"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 16 }"
        :colon="false"
        @submit="handleSubmit"
      >
        <a-form-item label="中文名">
          <a-input
            v-decorator="[
              'name',
              {
                initialValue: activeParam.name,
                rules: [{ required: true, message: '请输入！' }],
              },
            ]"
          />
        </a-form-item>

        <a-form-item label="英文名">
          <a-input
            v-decorator="[
              'code',
              {
                initialValue: activeParam.code,
                rules: [{ required: true, message: '请输入！' }],
              },
            ]"
          />
        </a-form-item>

        <a-form-item label="类型">
          <a-radio-group
            v-decorator="[
              'valueClass',
              {
                initialValue: activeParam.valueClass,
                rules: [{ required: true, message: '请选择！' }],
              },
            ]"
            button-style="solid"
          >
            <a-radio
              v-for="item in valueTypeList"
              :key="item.value"
              :value="item.value"
              >{{ item.name }}</a-radio
            >
          </a-radio-group>
        </a-form-item>

        <a-form-item label="设计值">
          <a-input
            v-decorator="[
              'designValue',
              {
                initialValue: activeParam.designValue,
              },
            ]"
          />
        </a-form-item>

        <a-form-item label="数值精度">
          <a-input-number
            :min="0"
            :max="16"
            v-decorator="[
              'decimalPrecision',
              {
                initialValue: activeParam.decimalPrecision,
              },
            ]"
            style="width: 100%"
          />
        </a-form-item>

        <a-form-item label="单位">
          <a-input
            v-decorator="[
              'unit',
              {
                initialValue: activeParam.unit,
                rules: [{ required: true, message: '请输入！' }],
              },
            ]"
          />
        </a-form-item>

        <a-form-item label="备注">
          <a-textarea
            :auto-size="{ minRows: 3 }"
            v-decorator="[
              'remark',
              {
                initialValue: activeParam.remark,
              },
            ]"
          />
        </a-form-item>

        <div class="right">
          <a-space>
            <a-button @click="cancel">取消</a-button>
            <a-button type="primary" html-type="submit" :loading="saveLoading"
              >保存</a-button
            >
          </a-space>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import { fetchList, editPublicParams } from "@/api/parameterization/param";
import { mapGetters } from "vuex";
export default {
  mixins: [watermark],

  data() {
    return {
      list: [],

      visible: false,
      form: this.$form.createForm(this),
      saveLoading: false,
      activeParam: {},
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    valueTypeList() {
      return this.findDataDict("bim_value_type");
    },
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      fetchList({
        paramType: 1,
        pageNum: 1,
        pageSize: 1000,
      }).then((res) => {
        if (res && Array.isArray(res.list)) {
          this.list = res.list;
        }
      });
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.saveLoading = true;
          console.log(values);

          editPublicParams([
            {
              ...this.activeParam,
              ...values,
            },
          ])
            .then(() => {
              this.cancel();
              this.getList();
            })
            .finally(() => {
              this.saveLoading = false;
            });
        }
      });
    },
    cancel() {
      this.visible = false;
      this.activeParam = {};
    },

    editText(text) {
      this.visible = true;
      this.activeParam = text;
    },
  },
};
</script>